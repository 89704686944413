<template>
  <tiptap-vuetify :disabled="isDisabled" v-model="content" :card-props="{ flat: isFlat }"
    :toolbar-attributes="{ color: '' }" :placeholder="placeholder" :extensions="getExtensions" />
</template>

<script>
import {
  TiptapVuetify,
  Image,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from "tiptap-vuetify";

export default {
  watch: {
    content() {
      if (this.content.length >= this.maxChars) {
        this.$emit('maxreached', this.content.length);
      }
      this.$emit("input", this.content);
    },

    value: {
      immediate: true,
      handler() {
        console.log(this.value)
        this.content = this.value;
      }
    }
  },
  computed: {
    getExtensions() {
      if (this.showNone) {
        return [];
      } else {
        return this.extensions;
      }
    }
  },
  props: {
    showNone: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String,
      default: 'Some really cool content here'
    },
    maxChars: {
      type: Number,
      default: 99999999999
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: "",
    },
    height: {
      type: String,
      default: '40vh'
    },
    isFlat: {
      type: Boolean,
      default: false,
    }
  },
  components: { TiptapVuetify },
  data: () => ({
    extensions: [
      Image,
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3],
          },
        },
      ],
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak,
    ],
    content: "",
  }),
};
</script>

<style scoped>
/deep/ .ProseMirror {
  min-height: 30vh;
  overflow-y: auto;
}
</style>