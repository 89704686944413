<template>
    <v-form>
        <v-container>
            <v-row>
                <v-col>
                    <dialog-date-picker :isRequired="false" :hideTitle="true" :date.sync="job.startDate"
                        dialogTitle="Start Date"></dialog-date-picker>
                </v-col>
                <v-col>
                    <v-text-field required v-model="job.poNumber" prepend-icon="mdi-pound-box-outline" label="PO Number"></v-text-field>
                </v-col>
                <v-col>
                    <dialog-date-picker :isRequired="false" :hideTitle="true" :date.sync="job.endDate"
                        dialogTitle="End Date"></dialog-date-picker>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-select :items="companyContacts" item-text="text" item-value="value" prepend-icon="mdi-account-star-outline" label="Company Contact"
                        v-model="job.contactId"></v-select>
                </v-col>
                <v-col>
                    <v-select prepend-icon="mdi-account-circle" label="Project Manager (CCS)" :items="coffeeCupContacts" item-text="text" item-value="value"
                        v-model="job.coffeeCupUserId"></v-select>
                </v-col>
                <v-col>
                    <v-select prepend-icon="mdi-home-group" label="Division" 
                    :items="['Support', 'Citrix', 'Development']"
                    v-model="job.division"></v-select>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-text-field label="Title" v-model="job.title" required :rules="[
                        x => !!x || 'Title is required'
                    ]"></v-text-field>
                    <!-- <v-textarea required :rules="[
                        x => !!x | 'Description is required'
                    ]" label="Description" rows="8"></v-textarea> -->
                    <label>Description</label>
                    <span class="float-right">

                    </span>
                    <div class="mt-2">
                        <trev-editor v-model="job.description" :isFlat="true" placeholder="SSD Upgrade for Nathan's slow PC
                    
£150 Surcharge" :showNone="false"></trev-editor>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>

<script>
import dialogDatePicker from '../base/dialogDatePicker.vue'
import TrevEditor from '../ui/trev-editor.vue'
export default {
    components: { dialogDatePicker, TrevEditor },
    watch: {
        job(newValue, oldValue) {
            this.$emit('input', this.job);
        }
    },
    props: {
        value: {
            type: Object,
            default: () => {}
        },
    },
    created () {
        this.job = JSON.parse(JSON.stringify(this.value));

        this.getCoffeeContacts();

        if(this.$route.params.companyId){
            this.getCompanyContacts();
        }
    },
    methods: {
        async getCompanyContacts(){
            return this.$courier.Companies.withId(this.$route.params.companyId).Contacts.getData(1, 200, '').then(p => {
                console.log(p);
                if(p && p.payload){
                    this.companyContacts = p.payload.map(x => ({
                        value: x.id,
                        text: x.firstName + ' ' + x.lastName
                    }));
                }
            })
        },
        async getCoffeeContacts() {
            return this.$courier.SystemUsers.getData(1, 200, '').then(response => {
                if(response && response.payload){
                    this.coffeeCupContacts = response.payload.map(x => ({
                        value: x.id,
                        text: x.firstName + ' ' + x.lastName
                    }));
                }
            });
        }
    },
    data() {
        return {
            companyContacts: [],
            coffeeCupContacts: [],
            job: {
                title: '',
                description: '',
                startDate: '',
                endDate: '',
                contactId: '',
                price: 0,
                coffeeCupUserId: '',
                division: '',
                poNumber: '',
            }
        }
    },
}
</script>

<style>

</style>