<template>
    <v-row>
        <v-col>
            <v-card>
                <v-toolbar elevation="0">
                    <v-toolbar-title>
                        Add Job
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn @click="AddJob" color="primary">
                        <v-icon class="mr-2">mdi-plus</v-icon>Save Job</v-btn>
                </v-toolbar>
                <v-card-text>
                    <trev-job-add v-if="!isLoading" v-model="job"></trev-job-add>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>

</template>

<script>
import trevJobAdd from '../../../../components/jobs/trev-job-add.vue'
export default {
    components: { trevJobAdd },
    created() {
        if (this.$route.params.jobId) {
            this.isLoading = true;
            //is editing
            this.getJob();
        } else {
            this.isLoading = false;
        }
    },
    methods: {
        getJob() {
            this.$courier.Jobs.getById(this.$route.params.jobId).then(job => {
                this.job = job;
                this.isLoading = false;
            });
        },
        AddJob() {
            if (this.$route.params.jobId) {
                this.$courier.Jobs.updateById(this.$route.params.jobId, this.job).then(updatedJob => {
                    this.$router.push({
                        name: 'Job View',
                        params: {
                            jobId: updatedJob.id
                        }
                    });
                })
            }
            else {
                this.job.status = 'Open';
                this.job.parentCompanyId = this.$route.params.companyId;
                this.$courier.Jobs.add(this.job).then(newJob => {
                    //reset the job thing? redirect back to company jobs page?

                    this.$router.push({
                        name: 'Job View',
                        params: {
                            jobId: newJob.id
                        }
                    });
                });
            }
        }
    },
    data() {
        return {
            isLoading: false,
            job: {}
        }
    },
}
</script>

<style>

</style>